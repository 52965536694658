import React from 'react';
import styled from 'styled-components';
import { MDXRenderer } from 'gatsby-plugin-mdx'
const formatNum = param => param;

const Styling = styled.div`
    /* width: 50%; */
    h2 {
        margin-top: 30px;
        text-transform: uppercase;
        font-family: 'Roboto Condensed', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
        font-size: 2em;
    }
    div {
        margin: 10px;
    }

`


const Rows = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* should only min-height when side by side */
    @media screen and (min-width: 850px) {
        min-height: 500px;
    }
    &:first-child {
        flex: 1;
    }
`

const Cols = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    & > div {
        align-self: center;
    }
`

const PriceLabel = styled.div`
    font-size: 1.25em;
`

const PricingButton = styled.a`
    white-space: nowrap;
    padding: 10px 20px;
    background-color: ${({ disabled }) => (disabled ? 'lightgrey' : 'lightgreen')};
    border-radius: 5px;
    color: white;
    transition: .25s;
    cursor: pointer;
    &:hover {
        background-color: ${({ disabled }) => (disabled ? 'lightgrey' : 'green')};
    }
`

const DomainDetailsPane = ({ node, handleContact, formDivId }) => {
    const {
        id,
        slug,
        frontmatter: {
            categories,
            domain_name,
            short_description,
            current_price,
            normal_price,
            hide_from_showcase,
            cover_image: {
                childImageSharp: {
                    gatsbyImageData
                }
            }
        },
        body
    } = node;

    // const modifiedDescHtml = `
    //     <style>
    //         .domain-desc * {
    //             font-size: 1.25em !important;
    //         }
    //     </style>
    //     <div class="domain-desc">${fullDescription}</div>
    // `;

    const buttonText = 'Contact Owner';
    // const buttonText = currentPrice ? 'Add to Cart' : 'Inquire Price';
    return (
        <Styling>
            <Rows>
                <div>
                    <h2>{domain_name}<br />Premium Domain</h2>
                    <MDXRenderer>
                        {body}
                    </MDXRenderer>
                </div>
                <div>
                    <div id={formDivId} />
                    <Cols>
                        <PriceLabel>{current_price ? `$${formatNum(current_price)}` : null}</PriceLabel>
                        <div>
                            <PricingButton
                                disabled={false}
                                onClick={handleContact}
                            >
                                {buttonText}
                            </PricingButton>
                        </div>
                    </Cols>
                </div>

            </Rows>
        </Styling>
    )
}

export default DomainDetailsPane;