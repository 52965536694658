import React, { useEffect, useState, Fragment } from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import Layout from '../layout'
import Container from '../Container'
import OutlinedContainer from '../OutlinedContainer'
// import ImgBox from '../ImgBox'
import DomainDetailsPane from '../DomainDetailsPane';
import loadScript from '../../helpers/load-script';
import Seo from '../seo';

const Cols = styled.div`
    & > * {
        /* border: 1px solid grey; */
        /* width: 100%; */
        flex: 1;
    }
    display: flex;
    flex-direction: row;
    /* justify-content: flex-start; */
    /* align-items: flex-start; */
    flex-wrap: wrap;
    @media screen and (max-width: 850px) {
        display: block;
    }
`
const StyledLink = styled(props => <Link {...props} />)`
    color: grey;
    text-transform: uppercase;
    border: 1px solid lightgrey;
    border-radius: 16px;
    padding: 5px 20px;
    text-decoration: none;
    margin: 5px auto 25px auto;
    display: inline-block;
    transition: .25s;
    &:hover {
        background-color: green;
        color: white;
    }
`

const ImgCol = styled.div`
    align-self: flex-start;
    @media screen and (max-width: 850px) {
        // at this width, is block - not flex
        text-align: center;
    }
`


const DomainProductPage = ({ pageContext: { node } }) => {
    // const {
    //     imageUrl,
    //     gatsbyImg,
    //     domain,
    //     shortDescription,
    //     fullDescription
    // } = props;

    const {
        // id,
        // slug,
        frontmatter: {
            // categories,
            domain_name,
            short_description,
            // current_price,
            // normal_price,
            // hide_from_showcase,
            cover_image: {
                childImageSharp: {
                    gatsbyImageData
                }
            }
        },
        // body
    } = node;

    const [hasForm, setForm] = useState(false);

    const setFormDomain = () => {
        const formDomainNameField = document.querySelector('input[name="mauticform[domainsource]"]')
        if (formDomainNameField) formDomainNameField.value = domain_name;
        window.document.title = `${domain_name} - A great domain name for your site.`;
    }

    const loadMauticForm = formId => {
        if (hasForm) return;
        setForm(true);
        const script = document.createElement('script');
        // script.src = 'https://bff.adstute.com/form/generate.js?id=5';
        script.src = 'https://crm.upchunk.com/form/generate.js?id=2';
        script.async = true;
        script.addEventListener('load', setFormDomain);
        const formElContainer = document.querySelector(`#${formId}`);
        if (formElContainer) formElContainer.append(script);
    }

    useEffect(() => {
        setFormDomain();
        loadScript('/js/mautic.js'); // is this for analytics or form?

        // we only want to useEffect when comparison of state array is false (in this case once)
    }, []);

    const defaultTitle = 'A great place for your site.';
    const title = `${domain_name} | ${short_description || defaultTitle}`


    return (
        <Fragment>
            <Layout overrideContent={<><h1>Premium Domain Names</h1></>}>
                <Seo
                    title={title}
                    author="@domfeed"
                    // imageUrl={imageUrl}
                    description={short_description}
                />
                <Container >
                    <OutlinedContainer style={{ width: '100%', marginTop: '20px' }}>
                        <Cols >
                            <ImgCol>
                                {/* {gatsbyImg ?
                                    gatsbyImg
                                    :
                                    <ImgBox
                                        width={512}
                                        height={512}
                                        src={premiumDomainImage}
                                    />
                                } */}
                                <GatsbyImage
                                    image={gatsbyImageData}
                                    alt="placeholder image"
                                />
                            </ImgCol>
                            <DomainDetailsPane
                                handleContact={() => loadMauticForm('interest-form')}
                                formDivId="interest-form"
                                disableContact={hasForm}
                                node={node}
                            />
                        </Cols>
                    </OutlinedContainer>
                    <div style={{ textAlign: 'center', marginTop: '10px' }} >
                        <StyledLink to="/premium-domains">View All Premium Domains</StyledLink>
                    </div>
                </Container>
            </Layout>
        </Fragment>
    )
};


export default DomainProductPage;